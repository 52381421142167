<template>
  <b-table
    :fields="tableFields"
    :items="dictTreeLocal"
    responsive="false"
    bordered
    sticky-header="true"
    :filter="filter.search"
    :filter-included-fields="filter.on"
    :tbody-tr-class="rowClass"
  >
    <template #head(abp)="scope">
      <div align="center">АБП</div>
      <br />
      <b-form-input
        id="filter-input"
        v-model="filter.abp"
        type="search"
      ></b-form-input>
    </template>

    <template #head(prg)="scope">
      <div align="center">БП</div>
      <br />
      <b-form-input
        id="filter-input"
        v-model="filter.prg"
        type="search"
      ></b-form-input>
    </template>

    <template #head(ppr)="scope">
      <div align="center">БПП</div>
      <br />
      <b-form-input
        id="filter-input"
        v-model="filter.ppr"
        type="search"
      ></b-form-input>
    </template>

    <template #head(name_ru)="scope">
      <div align="center">Наименование на русском языке</div>
      <br />

      <b-form-input
        id="filter-input"
        v-model="filter.name_ru"
        type="search"
      ></b-form-input>
    </template>

    <template #cell(action)="data">
      <b-button
        v-if="data.item.type === 3"
        class="text-right"
        @click="openChilds(data.item, data.index)"
      >
        <i class="icon icon-keyboard icon-rotate-270" v-if="data.item.open"></i>
        <i class="icon icon-keyboard icon-rotate-90" v-if="!data.item.open"></i>
      </b-button>
    </template>

    <template #cell(abp)="data">
      <div v-if="data.item.type === 3">{{ data.item.abp }}</div>
      <b-button
        v-if="data.item.type === 4"
        @click="openChilds(data.item, data.index)"
      >
        <i class="icon icon-keyboard icon-rotate-270" v-if="data.item.open"></i>
        <i class="icon icon-keyboard icon-rotate-90" v-if="!data.item.open"></i>
      </b-button>
    </template>

    <template class="tr" #cell(begDate)="data">
      <div style="white-space: nowrap">
        {{ millisToDate(data.item.begDate) }}
      </div>
    </template>

    <template class="tr" #cell(endDate)="data">
      <div class="tr">
        {{ millisToDate(data.item.endDate) }}
      </div>
    </template>

    <template #cell(ppr)="data">
      <div v-if="data.item.type === 4 && data.item.ppr === false">
        Нет подпрограмм
      </div>
      <div v-if="data.item.type !== 4 && data.item.ppr !== false">
        {{ data.item.ppr }}
      </div>
    </template>

     <template class="tr" #cell(budgetLevelId)="data">
        <div class="tr" v-if="data.item.budgetLevelId === 4">Бюджет МСУ</div>
        <div class="tr" v-if="data.item.budgetLevelId === 3">Рай.бюджет</div>
        <div class="tr" v-if="data.item.budgetLevelId === 2">ОБ</div>
        <div class="tr" v-if="data.item.budgetLevelId === 1">РБ</div>
        <div class="tr" v-if="data.item.budgetLevelId === 0">Не определен</div>
      </template>
  </b-table>
</template>

<script>
export default {
    props: {
        dictVedom: null
    },
    data() {
        return {
            dictTreeLocal: JSON.parse(JSON.stringify(this.dictVedom)),

            tableFields: [
                {
                    key: 'action',
                    label: ' '
                },

                {
                    key: 'abp',
                    label: 'АБП'
                },
                {
                    key: 'prg',
                    label: 'БП'
                },
                {
                    key: 'ppr',
                    label: 'БПП'
                },
                {
                    key: 'name_ru',
                    label: 'Наименование-Рус'
                },

                {
                    key: 'name_kk',
                    label: 'Наименование на казахском языке'
                },
                {
                    key: 'begDate',
                    label: 'Дата начала'
                },

                {
                    key: 'endDate',
                    label: 'Дата завершения'
                },

                {
                    key: 'budgetLevelId',
                    label: 'Уровень бюджета'
                },

                {
                    key: 'filter',
                    label: 'filter',
                    thClass: 'd-none',
                    tdClass: 'd-none'
                }
            ],

            selectedTree: [],
            budgetForm: [],
            open1: null,

            filter: {
                abp: null,
                prg: null,
                ppr: null,
                name_ru: null,
                search: null,
                on: ['filter', 'abp', 'prg', 'ppr', 'name_ru']
            }
        };
    },

    created() {
        this.$watch('filter.abp', this.filterUpdate);
        this.$watch('filter.prg', this.filterUpdate);
        this.$watch('filter.ppr', this.filterUpdate);
        this.$watch('filter.name_ru', this.filterUpdate);
    },

    methods: {
        filterUpdate() {
            this.filter.search
        = (this.filter.abp === null ? '' : this.filter.abp)
        + (this.filter.ppr === null ? '' : this.filter.ppr)
        + (this.filter.prg === null ? '' : this.filter.prg)
        + (this.filter.name_ru === null ? '' : this.filter.name_ru);
            if (this.filter.search.length === 0) {
                this.filter.search = null;
            }
        }, // формирует строку поиска

        async loadParentFetch(Parent) {
            if (Parent.open === true && Parent.type === 3) {
                const response = await fetch(
                    '/api/dict/ebk_func_types/vedom/abp?abp=' + Parent.abp + '&type=' + 4
                );
                this.selectedTree = await response.json();
                this.selectedTree.forEach(function (child) {
                    child.open = false;
                    child.visible = true;
                });
                const searchIndex = Parent.id;
                const index = this.dictTreeLocal.findIndex(
                    (el) => el.id === searchIndex
                );
                this.selectedTree.forEach((data) => {
                    this.dictTreeLocal.splice(index + 1, 0, data);
                });
            } else if (Parent.open === false && Parent.type === 3) {
                this.dictTreeLocal = this.dictTreeLocal.filter(
                    (child) => !(child.abp === Parent.abp && child.type === 4) && child.type !== 5
                );
            }

            if (Parent.open === true && Parent.type === 4) {
                const response = await fetch(
                    '/api/dict/ebk_func_types/vedom/prg?abp='
            + Parent.abp
            + '&prg='
            + Parent.prg
            + '&type='
            + 5
                );
                this.selectedTree = await response.json();
                if (this.selectedTree.length !== 0) {
                    this.selectedTree.forEach(function (item1) {
                        item1.open = false;
                        item1.visible = true;
                    });
                } else {
                    Parent.ppr = false;
                }

                const searchIndex = Parent.id;
                const index = this.dictTreeLocal.findIndex(
                    (el) => el.id === searchIndex
                );
                this.selectedTree.forEach((data) => {
                    this.dictTreeLocal.splice(index + 1, 0, data);
                });
            } else if (Parent.open === false && Parent.type === 4) {
                this.dictTreeLocal = this.dictTreeLocal.filter(
                    (child) => !(child.prg === Parent.prg && child.type === 5)
                );
            }
        },

        rowClass(item, type) {
            if (!item || type !== 'row') {
                return;
            }
            if (!item.visible) {
                return 'is-hidden';
            }

            if (item.type === 4) {
                return ' table-primary';
            }
        },

        openChilds(item) {
            if (item.open === false) {
                item.open = true;
                this.loadParentFetch(item);

                this.selectedTree = [];
            } else if (item.open === true) {
                item.open = false;
                this.loadParentFetch(item);
                this.selectedTree = [];
            }
        },

        millisToDate: function (millis) {
            if (!millis) {
                return '';
            }
            const date = new Date(millis);
            const year = date.getFullYear();
            let month = date.getMonth() + 1;
            if (month.toString().length === 1) {
                month = '0' + month;
            }
            let day = date.getDate();
            if (day.toString().length === 1) {
                day = '0' + day;
            }
            return year + '-' + month + '-' + day;
        }
    }
};
</script>

<style>
</style>