<template>
    <div>
        <b-input-group>
            <b-input-group-prepend>
                <b-input v-model="item.id" class="item-id" readonly
                         v-bind:class="{itemModified: item.modified }"></b-input>
                <div class="h4" :class="'level-'+level">
                    <b-badge v-b-toggle="codeField+'_'+item.id"
                             @click.prevent :variant="variant" class="item-badge">
                        <div v-if="!leaf">
                            <b-icon-dash v-if="collapseState" size="lg"></b-icon-dash>
                            <b-icon-plus v-else size="lg"></b-icon-plus>
                        </div>
                        <div v-else>
                            <b-icon-dot></b-icon-dot>
                        </div>
                        <b-icon-arrow-clockwise v-if="loaded" animation="spin"></b-icon-arrow-clockwise>
                    </b-badge>
                </div>
                <b-input v-model="item[codeField]" class="item-code" :readonly="!item.isnew" trim
                         :state="codeState"></b-input>

            </b-input-group-prepend>
            <transition appear mode="in-out" duration="50">
                <div v-if="page===0" class="item-input-group" key="names">
                    <b-input v-model="item.name_ru" @change="emitModified"
                             v-bind:class="{itemModified: item.modified }"
                             placeholder="На русском языке"></b-input>
                    <b-input v-model="item.name_kk" @change="emitModified"
                             v-bind:class="{itemModified: item.modified }"
                             placeholder="На казахском языке"></b-input>
                    <b-input-group-text @click="page=1"><strong class="text-danger"> &#62; </strong>
                    </b-input-group-text>
                </div>

                <div v-else-if="page===1" class="item-input-group" key="shortnames">
                    <b-input-group-text @click="page=0"><strong class="text-danger"> &#60; </strong>
                    </b-input-group-text>
                    <b-input v-model="item.short_name_ru" @change="emitModified"
                             v-bind:class="{itemModified: item.modified }"
                             placeholder="Краткое на русском языке"></b-input>
                    <b-input v-model="item.short_name_kk" @change="emitModified"
                             v-bind:class="{itemModified: item.modified }"
                             placeholder="Краткое на казахском языке"></b-input>
                    <b-input-group-text @click="page=2"><strong class="text-danger"> &#62; </strong>
                    </b-input-group-text>
                </div>

                <div v-else-if="page===2" class="item-input-group" key="dates">
                    <b-input-group-text @click="page=1"><strong class="text-danger"> &#60; </strong>
                    </b-input-group-text>
                    <b-datepicker v-model="item.beg_date" @change="emitModified"
                                  v-bind:class="{itemModified: item.modified }"
                                  placeholder="Дата начала"></b-datepicker>
                    <b-datepicker v-model="item.end_date" @change="emitModified"
                                  v-bind:class="{itemModified: item.modified }"
                                  placeholder="Дата окончания"></b-datepicker>
                </div>
            </transition>
            <b-input-group-append v-if="level==3">
                <b-select :options="budgetDevelop" v-model="item.develop_type" @change="emitModified"
                          v-bind:class="{itemModified: item.modified }">
                </b-select>
                <b-form-checkbox class="mt-2" v-model="item.is_sequest" @change="emitModified" switch v-b-tooltip.hover title="Секвестируемая">
                </b-form-checkbox>
                <b-select :options="budgetTransfer" v-model="item.transfer" @change="emitModified"
                          v-bind:class="{itemModified: item.modified }">
                </b-select>
            </b-input-group-append>
            <b-input-group-append>
                <b-select :options="budgetLevel" v-model="item.budget_level_id" @change="emitModified"
                          v-bind:class="{itemModified: item.modified }"></b-select>
                <b-dropdown id="dropdown-1" text="Действия">
<!--                    <b-dropdown-item  v-b-modal="'modal_'+item.id">Атрибуты</b-dropdown-item>-->
                    <b-dropdown-item v-if="item.modified" @click="undoModifed">Отменить</b-dropdown-item>
                    <b-dropdown-item @click="deleteItem">Удалить</b-dropdown-item>
                    <b-dropdown-item v-if="item.modified" @click="saveItem">Сохранить</b-dropdown-item>
                    <b-dropdown-item v-if="collapseState" @click="addChild">Добавить</b-dropdown-item>
                    <b-dropdown-item @click="checkChild">Проверить</b-dropdown-item>
                    <b-dropdown-item v-if="item.type===5" @click="openReestr">Реестр</b-dropdown-item>
                </b-dropdown>
            </b-input-group-append>
        </b-input-group>
        <b-collapse :id="codeField+'_'+item.id" @show="emitShow(item.id)" v-model="collapseState">
            <slot></slot>
        </b-collapse>
        <b-modal :id="'modal_'+item.id" title="BootstrapVue">
            <p class="my-4">Hello from modal!</p>
        </b-modal>
    </div>
</template>

<script>
import {BIconPlus, BIconDash, BIconDot} from 'bootstrap-vue';

export default {
    name: 'DictItem',
    components: {BIconPlus, BIconDash, BIconDot},
    props: {
        item: {
            type: Object
        },

        codeField: {
            type: String
        },
        variant: {
            type: String,
            default: 'primary'
        },
        leaf: {
            type: Boolean,
            default: false
        },
        level: {
            type: String,
            default: '0'
        },
        loaded: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            collapseState: false,
            savedItem: {},
            page: 0,
            budgetLevel: [
                {
                    value: null,
                    text: 'Не выбрано'
                },
                {
                    value: 1,
                    text: 'РБ'
                },
                {
                    value: 2,
                    text: 'ОБ'
                },
                {
                    value: 3,
                    text: 'Рай.бюджет'
                },
                {
                    value: 4,
                    text: 'Бюджет МСУ'
                }
            ],
            budgetDevelop: [
                {
                    value: null,
                    text: 'Не выбрано'
                },
                {
                    value: 0,
                    text: 'Текущаяя'
                },
                {
                    value: 1,
                    text: 'Развития'
                }
            ],
            budgetTransfer: [
                {
                    value: null,
                    text: 'Не выбрано'
                },
                {
                    value: 1,
                    text: 'Трансферты'
                },
            ],
            codeState: null
        };
    },
    computed: {},
    methods: {
        emitShow() {
            this.$emit('collapse-show', this.item, this);
        },
        emitModified() {
            this.$emit('modified', this.item, this);
        },
        setLoaded(value) {
            this.loaded = value;
        },
        undoModifed() {
            this.$emit('undo-modified', this.item, this.savedItem);
        },
        saveItem() {
            this.$emit('save-item', this.item, this);
        },
        addChild() {
            this.$emit('add-child', this.item, this);
        },
        checkChild() {
            return this.$emit('check-child', this.item, this, this.codeField);
        },
        deleteItem() {
            this.$emit('delete-item', this.item, this);
        },
        setCodeState(value) {
            this.codeState = value;
        },
        openReestr() {
            return this.$emit('open-reestr', this.item, this);
        }
    },
    mounted() {
        this.savedItem = JSON.parse(JSON.stringify(this.item));
    }
};
</script>

<style scoped>

.item-badge {
    min-width: 3em;
}

.level-1 {
    margin-left: 2em;
}

.level-2 {
    margin-left: 4em;
}

.level-3 {
    margin-left: 6em;
}

.level-4 {
    margin-left: 8em;
}

.item-id {
    width: 5em;
}

.itemModified {
    font-weight: 800;
}

.item-code {
    width: 4em;
}

.item-input-group {
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    /*padding: 0.375rem 0.75rem;*/
    margin-bottom: 0;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    text-align: center;
    white-space: nowrap;
    background-color: #e9ecef;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
}
</style>