<template>
    <div>
        <div class="filter-container">
            <div class="left-content">
                <b-dropdown class="filter-dropdown" variant="default" ref="drop">
                    <template #button-content>
                        <span class="lc"><i class="icon icon-filter"></i> Фильтр</span><span class="rc"><i class="icon icon-keyboard"></i></span>
                    </template>
                        <div>
                            <div class="top-content">
                                <span>Параметры фильтра</span>
                                <i class="icon icon-close" @click="$refs.drop.hide(true)"></i>
                            </div>
                            <div class="filter-block">
                                <b-form-group label="Код">
                                    <b-form-input v-model="selected[0].code"></b-form-input>
                                </b-form-group>
                            </div>
                            <div class="filter-block">
                                <b-form-group label="Область">
                                    <b-form-input v-model="selected[0].obl"></b-form-input>
                                </b-form-group>
                            </div>
                            <div class="filter-block">
                                <b-form-group label="Наименование">
                            <textarea
                                cols="50"
                                v-model="selected[0].name_ru">
                            </textarea>
                                </b-form-group>
                            </div>
                            <div class="filter-block">
                                <b-form-group label="Атауы">
                            <textarea
                                cols="50"
                                v-model="selected[0].name_kk">
                            </textarea>
                                </b-form-group>
                            </div>
                            <div class="filter-block">
                                <b-form-group label="Дата начала">
                                    <b-form-input type="date"
                                                  v-model="selected[0].beg_date"
                                    ></b-form-input>
                                </b-form-group>
                            </div>
                            <div class="filter-block">
                                <b-form-group label="Дата окончания">
                                    <b-form-input type="date"
                                                  v-model="selected[0].end_date"
                                    ></b-form-input>
                                </b-form-group>
                            </div>
                            <div class="filter-block">
                                <b-button variant="success" @click="saveItem">Сохранить</b-button>
                            </div>
                        </div>
                </b-dropdown>
            </div>
        </div>
        <div class="table-container">
            <b-table
                :fields="tableFields"
                :items="dictBP"
                responsive="true"
                bordered
                hover
                head-variant="light"
                sticky-header
                no-border-collapse
                select-mode="single"
                ref="selectableTable"
                selectable
                @row-selected="onRowSelected"
            >
                <template #head(more)="data">
                    <b-button @click="addItem" style="color:green">+</b-button>
                </template>
                <template #cell(more)="data">
                    <b-button @click="deleteItem(data.item, data.index)">
                        <i class="icon icon-close"></i>
                    </b-button>
                </template>
            </b-table>
        </div>
    </div>
</template>

<script>
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import store from '@/services/store';

export default {
    name: 'DictBp',
    data() {
        return {
            tableFields: [
                {
                    key: 'code',
                    label: 'Код'
                },
                {
                    key: 'obl',
                    label: 'Область'
                },
                {
                    key: 'name_ru',
                    label: 'Наименование'
                },
                {
                    key: 'name_kk',
                    label: 'Атауы'
                },
                {
                    key: 'beg_date',
                    label: 'Дата начало'
                },
                {
                    key: 'end_date',
                    label: 'Дата окончания'
                },
                {
                    key: 'more',
                    label: 'Удалить'
                }
            ],
            selected: [{
                id: 0,
                code: '',
                obl: '',
                name_ru: '',
                name_kk: '',
                beg_date: '',
                end_date: ''
            }],

            dictBP: []
        };
    },

    mounted() {
        this.loadDict();
    },

    methods: {
        makeToast(variant, title, tostbody) {
            this.$bvToast.toast(tostbody, {
                title: title,
                variant: variant,
                toaster: 'b-toaster-top-center',
                autoHideDelay: 5000,
                appendToast: true
            })
        },

        async loadDict() {
            try {
                const response = await fetch('/api-py/dictionary/budget_project/?actual=0');
                this.dictBP = await response.json();
                for (const bp of this.dictBP) {
                    this.$set(bp, 'order', parseInt(bp.code));
                }
                this.dictBP.sort(this.sortByField('order'));
            } catch (error) {
                this.makeToast('danger', 'Ошибка запроса loadDict', error.toString());
            }
        },

        onRowSelected(item) {
            this.selected = item;
        },

        addItem() {
            this.selected = [{}];
            const item = {
                id: 0,
                code: '',
                obl: '',
                name_ru: '',
                name_kk: '',
                beg_date: '',
                end_date: '',
                order: this.dictBP.length
            }
            this.selected = [item];
            // this.$refs.selectableTable.clearSelected();
        },

        async saveItem() {
            const response = await fetch('/api-py/save-bp', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json;charset=utf-8'
                },
                body: JSON.stringify(this.selected[0])
            });
            const result = await response.json();
            if ((response.status === 200) &&  (result.result === 'success')) {
                if (this.selected[0].id == 0) {
                    this.selected[0].id = result.id;
                    this.dictBP.push(this.selected[0]);
                }
                this.makeToast('success', 'Сообщение', 'Элемент сохранен')
            }
        },

        deleteItem(item, index) {
            this.$bvModal.msgBoxConfirm(
                'Подтвердите удаление: \"' + item.name_ru + '\" ',
                {
                    title: 'Подтверждение',
                    size: 'lg',
                    buttonSize: 'sm',
                    okVariant: 'danger',
                    okTitle: 'YES',
                    cancelTitle: 'NO',
                    footerClass: 'p-2',
                    hideHeaderClose: false,
                    centered: true
                })
                .then(value => {
                    if (value) {
                        this.delete(item, index);
                    }
                })
                .catch(error => {
                    this.makeToast('danger', 'Ошибка удаления', error.toString());
                });

        },

        async delete(item, index) {
            const response = await fetch('/api-py/save-bp', {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json;charset=utf-8'
                },
                body: JSON.stringify(item)
            });
            const result = await response.json();
            if ((response.status === 200) && (result.result === 'success')) {
                this.dictBP.splice(index, 1);
                this.makeToast('success', 'Сообщение', 'Элемент удален');
            }
        },

        sortByField(field) {
            return (a, b) => (a[field] > b[field] ? 1 : -1);
        }
    }
};
</script>
<style>
.b-table-sticky-header {
    max-height: 74%;
}

.is-hidden {
    display: none !important;
}
</style>
