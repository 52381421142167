<template>
    <b-tabs class="tabs-in-tabs">
      <b-tab  title="Классификация расходов" active>
          <div class="filter-container no-dropdown">
              <div class="filter-block">
<!--                  label="Вид таблицы"-->
                  <b-form-group>
                      <b-form-select
                          v-model="view"
                          :options="views"
                          value-field="value"

                      ></b-form-select>
                  </b-form-group>
              </div>
          </div>
              <div class="table-container">
 <b-progress variant="success" v-show="bar<100" height="10px" :value="bar" striped animated></b-progress>
            <template v-if="view == 1">
           <costFunc
            ref="costfunc"
            v-if="dictTree !== null"
            :dictTree="dictTree"
            @Resetload="Resetload"
           ></costFunc>
            </template>
            <template v-if="view == 2">
            <ebkVedom
            ref="ebkvedom"
            v-if="dictVedom !== null"
            :dictVedom="dictVedom"
           ></ebkVedom>
            </template>
            <template v-if="view == 3">
            <ebkEco
             @Resetload="Resetload"
            ref="ebkeco"
            v-if="dictEco !== null"
            :dictEco="dictEco">

            </ebkEco>
            </template>
        </div>
         </b-tab>
    </b-tabs>



</template>

<script>
import axios from 'axios';
import EbkVedom from '@/modules/budget/components/EbkVedom';
import EbkEco from '@/modules/budget/components/EbkEco';
import CostFunc from '@/modules/budget/components/CostFunc';
export default {
    components: { CostFunc, EbkVedom, EbkEco },
    data() {
        return {
            bar: 0,
            selected: null,
            dictTree: null,
            dictVedom: null,
            dictEco: null,
            view: 1,


            item: {
                gr: 2,
                pgr: 1
            },



            views: [
                { value: 1, text: 'Функциональная' },
                { value: 2, text: 'Ведомственная' },
                { value: 3, text: 'Экономическая' }
            ]
        };
    },
    async created() {
        await this.loadDatas();
    },

    methods: {

        Resetload() {
            this.loadDatas();
        },

        async loadDatas() {
            this.dictTree = null;
            this.dictVedom = null;
            this.dictEco = null;
            this.bar = 15;

            await this.loadParentFunc();
            await this.loadParentVedom();
            await this.loadParentEco();
        },




        async loadParentFunc() {
            this.bar = 30;
            const response = await fetch('/api/dict/ebk_func_types/gr');
            this.dictTree = await response.json();
            this.dictTree.map(function (item) {
                item.open = false;
                item.visible = true;
            });
        },

        async loadParentVedom() {
            this.bar = 50;
            const response = await fetch('/api/dict/ebk_func_types/abp');
            this.dictVedom = await response.json();
            this.dictVedom.map(function (item) {
                item.open = false;
                item.visible = true;
            });
        },

        async loadParentEco() {
            this.bar = 75;
            const response = await fetch('/api/dict/dict_ebk_ek/pcl');
            this.dictEco = await response.json();
            this.dictEco.map(function (item) {
                item.open = false;
                item.visible = true;
            });
            this.bar = 100;
        }

        // async loadBudgetLevel() {
        //     if (this.dictBudgetLevel.length === 0) {
        //         const response = await fetch('/api/dict/dict_budget_level');
        //         this.dictBudgetLevel = await response.json();
        //     }
        // }





    }
};
</script>