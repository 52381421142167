<template>
    <div>
        <div class="filter-container">
            <div class="left-content">
                <b-dropdown class="filter-dropdown" variant="default" ref="drop">
                    <template #button-content>
                        <span class="lc"><i class="icon icon-filter"></i> Фильтр</span><span class="rc"><i class="icon icon-keyboard"></i></span>
                    </template>
                    <div>
                        <div class="top-content">
                            <span>Параметры фильтра</span>
                            <i class="icon icon-close" @click="$refs.drop.hide(true)"></i>
                        </div>
                        <div class="filter-block">
                            <b-form-group label="Категория*">
                                <b-form-input v-model="selected.kat"></b-form-input>
                            </b-form-group>
                        </div>
                        <div class="filter-block">
                            <b-form-group label="Класс">
                                <b-form-input v-model="selected.cls"></b-form-input>
                            </b-form-group>
                        </div>
                        <div class="filter-block">
                            <b-form-group label="Подкласс">
                                <b-form-input v-model="selected.pcl"></b-form-input>
                            </b-form-group>
                        </div>
                        <div class="filter-block">
                            <b-form-group label="Специфика">
                                <b-form-input v-model="selected.spf"></b-form-input>
                            </b-form-group>
                        </div>
                        <div class="filter-block">
                            <b-form-group label="Наименование*">
                            <textarea v-model="selected.name_ru">
                            </textarea>
                            </b-form-group>
                        </div>
                        <div class="filter-block">
                            <b-form-group label="Атауы">
                            <textarea v-model="selected.name_kk">
                            </textarea>
                            </b-form-group>
                        </div>
                        <!-- <div class="filter-block">
                            <b-form-group label="Атауы">
                                    <textarea style="width: 300px" v-model="selected.name_kk">
                                    </textarea>
                            </b-form-group>
                        </div> -->
                        <div class="filter-block">
                            <b-form-group label="Дата начала">
                                <b-form-input type="date" v-model="selected.beg_date"
                                ></b-form-input>
                            </b-form-group>
                        </div>
                        <div class="filter-block">
                            <b-form-group label="Дата окончания">
                                <b-form-input type="date" v-model="selected.end_date"
                                ></b-form-input>
                            </b-form-group>
                        </div>
                        <div class="filter-block">
                            <b-button variant="success" @click="saveItem">Сохранить</b-button>
                        </div>
                    </div>
                </b-dropdown>
            </div>
        </div>
        <div class="table-container">
            <b-table
                :fields="tableFields"
                :items="table"
                :tbody-tr-class="rowClass"
                responsive="true"
                bordered
                head-variant="light"
                sticky-header
                no-border-collapse
                select-mode="single"
                ref="selectableTable"
                selectable
                @row-selected="onRowSelected"
            >
                <template #head(action)="scope">
                    <b-button @click="openAll()">
                        <i class="icon icon-keyboard icon-rotate-270" v-if="open"></i>
                        <i class="icon icon-keyboard icon-rotate-90" v-if="!open"></i>
                    </b-button>
                </template>
                <template #head(more)="data">
                    <b-button @click="addItem(null)" style="color:green">+</b-button>
                </template>

                <template #cell(action)="data">
                    <b-button v-if="data.item.type == 1" @click="openChilds(data.item)">
                        <i class="icon icon-keyboard icon-rotate-270" v-if="data.item.open"></i>
                        <i class="icon icon-keyboard icon-rotate-90" v-if="!data.item.open"></i>
                    </b-button>
                </template>
                <template #cell(kat)="data">
                    <div v-if="data.item.type == 1">{{ data.value }}</div>
                    <b-button v-if="data.item.type == 2" @click="openChilds(data.item)">
                        <i class="icon icon-keyboard icon-rotate-270" v-if="data.item.open"></i>
                        <i class="icon icon-keyboard icon-rotate-90" v-if="!data.item.open"></i>
                    </b-button>
                </template>
                <template #cell(cls)="data">
                    <div v-if="data.item.type == 2">{{ data.value }}</div>
                    <b-button v-if="data.item.type == 3" @click="openChilds(data.item)">
                        <i class="icon icon-keyboard icon-rotate-270" v-if="data.item.open"></i>
                        <i class="icon icon-keyboard icon-rotate-90" v-if="!data.item.open"></i>
                    </b-button>
                </template>
                <template #cell(pcl)="data">
                    <div v-if="data.item.type == 3">{{ data.value }}</div>
                </template>
                <template #cell(more)="data">
                    <b-button class="more" @click="data.item.more = !data.item.more">
                        <i class="icon icon-more" v-if="!data.item.more"></i>
                        <i class="icon icon-close" v-else></i>
                        <div class="more-block" v-if="data.item.more">
                            <ul>
                                <li v-if="data.item.type < 5"><a href="#!1" @click="addItem(data.item)">Добавить</a></li>
<!--                                <li><a href="#!1" @click="deleteItem(data.item, data.index)">Удалить</a></li>-->
                            </ul>
                        </div>
                    </b-button>
                </template>
            </b-table>
        </div>
    </div>
</template>

<script>
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';

export default {
    name: 'DictDoh',
    data() {
        return {
            tableFields: [
                {
                    key: 'action',
                    label: ' '
                },
                {
                    key: 'kat',
                    label: 'КАТ'
                },
                {
                    key: 'cls',
                    label: 'КЛ'
                },
                {
                    key: 'pcl',
                    label: 'ПКЛ'
                },
                {
                    key: 'spf',
                    label: 'СП'
                },
                {
                    key: 'name_ru',
                    label: 'Наименование'
                },
                {
                    key: 'name_kk',
                    label: 'Атауы'
                },
                {
                    key: 'beg_date',
                    label: 'Дата начало'
                },
                {
                    key: 'end_date',
                    label: 'Дата окончания'
                },
                {
                    key: 'more',
                    label: 'Действие'
                }
            ],
            selected: {},

            dictTree: [],
            table: [],

            open: true
        };
    },

    async mounted() {
        await this.loadDict();
        await this.prepareDatas();
        this.openAll();
    },

    beforeUpdate() {
        this.table.forEach(row => {
            if ([1, 2, 3].includes(row.type)) {
                row._rowVariant = 'info';
            }
        })
    },

    methods: {
        addItem(parent) {
            const that = this;

            that.selected = {};
            const item = {
                id: 0,
                parent_id: (parent == null ? 0 : parent.id),
                name_ru: '',
                name_kk: '',
                beg_date: '',
                end_date: ''
            }
            if (parent == null) {
                that.$set(item, 'kat', that.dictTree.length + 1);
                that.$set(item, 'cls', null);
                that.$set(item, 'pcl', null);
                that.$set(item, 'spf', null);
                that.$set(item, 'type', 1);
                that.$set(item, 'child', []);
            } else {
                that.$set(item, 'kat', parent.kat);
                that.$set(item, 'cls', parent.cls);
                that.$set(item, 'pcl', parent.pcl);
                that.$set(item, 'spf', parent.spf);

                if (parent.type == 1) {
                    that.$set(item, 'kat_item', parent);
                    that.$set(item, 'type', 2);
                    that.$set(item, 'child', []);
                }
                if (parent.type == 2) {
                    that.$set(item, 'cls_item', parent);
                    that.$set(item, 'kat_item', parent.parent);
                    that.$set(item, 'type', 3);
                    that.$set(item, 'child', []);
                }
                if (parent.type == 3) {
                    that.$set(item, 'pcl_item', parent);
                    that.$set(item, 'cls_item', parent.parent);
                    that.$set(item, 'kat_item', parent.parent.parent);
                    that.$set(item, 'type', 5);
                }
            }
            that.selected = item;
            // this.$refs.selectableTable.clearSelected();
        }, // добавляет элемент

        createTable(elem, parent_id) { // создание таблицы на основе дерева
            const that = this;

            const item = Object.assign({}, elem);

            that.$set(item, 'parent_id', parent_id);
            that.$set(item, 'visible', true);

            if (item.type > 1) {
                Object.defineProperty(item, 'parent', {
                    get: function() {
                        for (const row of that.table) {
                            if (item.parent_id == row.id) {
                                return row;
                            }
                        }
                    }
                });
            }

            if (item.type !== 5) {
                Object.defineProperty(item, 'child_size', {
                    get: function() {
                        let size = 0;
                        for (const row of that.table) {
                            if (item.id == row.parent_id) {
                                size++;
                            }
                        }
                        return size;
                    }
                });
                that.$set(item, 'open', true);
                that.$set(item, 'hasChild', true);
            }
            that.$set(item, 'index', that.table.length);
            that.$set(that.table, that.table.length, item);

            if (item.hasChild) {
                for (const ch of item.child) {
                    that.createTable(ch, item.id);
                }
                delete item.child;
            }
        }, // древовидную выборку преобразовывает в таблицу (для отображения)

        async delete(item) {
            const that = this;

            const response = await fetch('/api-py/save-income-item', {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json;charset=utf-8'
                },
                body: JSON.stringify(item)
            });
            const result = await response.json();
            if ((response.status === 200) && (result.result === 'success')) {

                if (item.type == 1) {
                    that.dictTree.forEach((el, index) => {
                        if (el.id == item.id) {
                            that.dictTree.splice(index, 1);
                        }
                    })
                }
                if (item.type == 2) {
                    const katExist = that.dictTree.filter(function (el) {
                        if (el.id == item.kat_item.id) {
                            return el;
                        }
                    })
                    katExist[0].child.forEach((el, index) => {
                        if (el.id == item.id) {
                            katExist[0].child.splice(index, 1);
                        }
                    })
                }
                if (item.type == 3) {
                    const katExist = that.dictTree.filter(function (el) {
                        if (el.id == item.kat_item.id) {
                            return el;
                        }
                    })
                    const clsExist = katExist[0].child.filter(function (el) {
                        if (el.id == item.cls_item.id) {
                            return el;
                        }
                    })
                    clsExist[0].child.forEach((el, index) => {
                        if (el.id == item.id) {
                            clsExist[0].child.splice(index, 1);
                        }
                    })
                }
                if (item.type == 5) {
                    const katExist = that.dictTree.filter(function (el) {
                        if (el.id == item.kat_item.id) {
                            return el;
                        }
                    })
                    const clsExist = katExist[0].child.filter(function (el) {
                        if (el.id == item.cls_item.id) {
                            return el;
                        }
                    })
                    const pclExist = clsExist[0].child.filter(function (el) {
                        if (el.id == item.pcl_item.id) {
                            return el;
                        }
                    })
                    pclExist[0].child.forEach((el, index) => {
                        if (el.id == item.id) {
                            pclExist[0].child.splice(index, 1);
                        }
                    })
                }

                that.prepareDatas();

                that.makeToast('success', 'Сообщение', 'Элемент удален');
            }
        },

        deleteItem(item, index) {
            const that = this;
            that.$bvModal.msgBoxConfirm(
                'Подтвердите удаление: \"' + item.name_ru + '\" ',
                {
                    title: 'Подтверждение',
                    size: 'lg',
                    buttonSize: 'sm',
                    okVariant: 'danger',
                    okTitle: 'YES',
                    cancelTitle: 'NO',
                    footerClass: 'p-2',
                    hideHeaderClose: false,
                    centered: true
                })
                .then(value => {
                    if (value) {
                        if (item.type == 2) {
                            that.$set(item, 'kat_item', item.parent);
                        }
                        if (item.type == 3) {
                            that.$set(item, 'cls_item', item.parent);
                            that.$set(item, 'kat_item', item.parent.parent);
                        }
                        if (item.type == 5) {
                            that.$set(item, 'pcl_item', item.parent);
                            that.$set(item, 'cls_item', item.parent.parent);
                            that.$set(item, 'kat_item', item.parent.parent.parent);
                        }
                        that.delete(item);
                    }
                })
                .catch(error => {
                    this.makeToast('danger', 'Ошибка удаления', error.toString());
                });
        }, // удаляет элемент

        getRowKey(row, keys) {
            let key = '';
            for (const k of keys) {
                key = key + this.padLeadingZeros(row[k], 3) + '.';
            }
            return key;
        }, // преобразует значения выбранных полей в код

        keyup13: function (event) {
            event.preventDefault();
            // Isolate the node that we're after
            const currentNode = event.target;
            // find all tab-able elements
            const allElements = document.querySelectorAll('input'); // area, object, select, [contenteditable]
            // Find the current tab index.
            const currentIndex = [...allElements].findIndex(el => currentNode.isEqualNode(el));
            // select/focus the following element
            const targetIndex = (currentIndex + 1) % allElements.length;
            if (targetIndex < allElements.length) {
                allElements[targetIndex].select();
            }
        }, // enter работает как tab

        async loadDict() { // дерево доходов
            const that = this;

            let items = [];
            try {
                const response = await fetch('/api-py/get-dict-income/');
                items = await response.json();
            } catch (error) {
                that.makeToast('danger', 'Ошибка запроса справочника доходов', error.toString());
                return;
            }

            const katMap = new Map(); // категория
            const clsMap = new Map(); // класс
            const pclMap = new Map(); // подкласс
            const spfMap = new Map(); // специфика

            const promise = new Promise(function(resolve, reject) {

                for (const row of items) {
                    that.$set(row, 'kat', row.kat);
                    that.$set(row, 'cls', row.clss);
                    that.$set(row, 'more', false);
                    if (row.type < 5) {
                        that.$set(row, 'child', []);
                    }
                    if (row.type === 1) {
                        katMap.set(that.getRowKey(row, ['kat']), row);
                    }
                    if (row.type === 2) {
                        row.cls = that.padLeadingZeros(row.cls, 2);
                        clsMap.set(that.getRowKey(row, ['kat', 'cls']), row);
                    }
                    if (row.type === 3) {
                        pclMap.set(that.getRowKey(row, ['kat', 'cls', 'pcl']), row);
                    }
                    if (row.type === 5) {
                        row.spf = that.padLeadingZeros(row.spf, 2);
                        spfMap.set(that.getRowKey(row, ['kat', 'cls', 'pcl', 'spf']), row);
                    }
                }
                resolve({katMap: katMap, clsMap: clsMap, pclMap: pclMap, spfMap: spfMap});
            })
            promise.then(
                result => {
                    for (const spf of spfMap.values()) {
                        const pcl = pclMap.get(this.getRowKey(spf, ['kat', 'cls', 'pcl']));
                        if (pcl !== undefined) {
                            pcl.child.push(spf);
                        }
                    }
                    for (const pcl of pclMap.values()) {
                        const cls = clsMap.get(this.getRowKey(pcl, ['kat', 'cls']));
                        if (cls !== undefined) {
                            cls.child.push(pcl);
                        }
                    }
                    for (const cls of clsMap.values()) {
                        const kat = katMap.get(this.getRowKey(cls, ['kat']));
                        if (kat !== undefined) {
                            kat.child.push(cls);
                        }
                    }
                    for (const kat of katMap.values()) {
                        that.dictTree.push(kat);
                    }
                },
                error => {
                    that.makeToast('danger', 'Ошибка loadDict', error.toString());
                }
            )
        }, // собирает справочник DictEbkDoh в виде дерева с помощью Map-ов

        makeToast(variant, title, tostbody) {
            this.$bvToast.toast(tostbody, {
                title: title,
                variant: variant,
                toaster: 'b-toaster-top-center',
                autoHideDelay: 5000,
                appendToast: true
            })
        }, // сообщение

        noAbc: function (evt) {
            // const regex = new RegExp('^-?[0-9]+$');\.?
            const regex = new RegExp('^\\d*\\d{0,9}$');
            const key = String.fromCharCode(!evt.charCode ? evt.which : evt.charCode);
            if (!regex.test(key)) {
                evt.preventDefault();
                return false;
            }
        }, // вводит только цифры

        onRowSelected(arr) {
            if (arr.length > 0) {
                this.selected = arr[0];
            }
        }, // выделяет строку

        openAll() {
            this.open = !this.open;

            for (const row of this.table) {
                row.visible = this.open;
                if ([1, 2, 3, 4].includes(row.type)) {
                    row.open = this.open;
                }
                if ([0, 1].includes(row.type)) {
                    row.visible = true;
                }
            }
        }, // открывает/закрывает все ветки

        openChilds(parent, bool) {
            parent.open = (bool == undefined ? !parent.open : bool);

            for (const row of this.table) {
                if (parent.id == row.parent_id) {
                    if ([1, 2, 3].includes(row.type)) {
                        this.openChilds(row, parent.open);
                    }
                    row.visible = parent.open;
                }
            }
        }, // открывает/закрывает ветку до конечного элемента

        padLeadingZeros(num, size) {
            let s = String(num);
            while (s.length < size) { s = '0' + s; }
            return s;
        }, // добавляет 0-ли перед num до size-значного размера

        async prepareDatas() {

            this.table.splice(0);
            for (const item of this.dictTree) {
                await this.createTable(item, 0);
            }
            await this.openAll();
        }, // подготовка отображения данных

        rowClass(item, type) {
            if (!item || type !== 'row') { return; }
            if (!item.visible) { return 'is-hidden'; }
        }, // задает класс 'is-hidden' заданной строке

        async save() {
            const that = this;

            const response = await fetch('/api-py/save-income-item', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json;charset=utf-8'
                },
                body: JSON.stringify(this.selected)
            });
            const result = await response.json();
            if ((response.status === 200) && (result.result === 'success')) {
                if (that.selected.id == 0) {
                    that.selected.id = result.id;
                    if (that.selected.type == 1) {
                        that.$set(that.dictTree, that.dictTree.length, that.selected);
                    }
                    if (that.selected.type == 2) {
                        const katExist = that.dictTree.filter(function (el) {
                            if (el.id == that.selected.kat_item.id) {
                                return el;
                            }
                        })
                        if (katExist.length == 0) {
                            that.makeToast('success', 'Предупреждение', 'Родитель элемента не найден!');
                            return;
                        } else {
                            katExist[0].child.push(that.selected);
                        }
                        that.selected.cls = that.padLeadingZeros(that.selected.cls, 2);
                    }
                    if (that.selected.type == 3) {
                        const katExist = that.dictTree.filter(function (el) {
                            if (el.id == that.selected.kat_item.id) {
                                return el;
                            }
                        })
                        const clsExist = katExist[0].child.filter(function (el) {
                            if (el.id == that.selected.cls_item.id) {
                                return el;
                            }
                        })
                        clsExist[0].child.push(that.selected);
                        that.selected.cls = that.padLeadingZeros(that.selected.cls, 2);
                    }
                    if (that.selected.type == 5) {
                        const katExist = that.dictTree.filter(function (el) {
                            if (el.id == that.selected.kat_item.id) {
                                return el;
                            }
                        })
                        const clsExist = katExist[0].child.filter(function (el) {
                            if (el.id == that.selected.cls_item.id) {
                                return el;
                            }
                        })
                        const pclExist = clsExist[0].child.filter(function (el) {
                            if (el.id == that.selected.pcl_item.id) {
                                return el;
                            }
                        })
                        pclExist[0].child.push(that.selected);
                        that.selected.cls = that.padLeadingZeros(that.selected.cls, 2);
                        that.selected.spf = that.padLeadingZeros(that.selected.spf, 2);
                    }
                    that.prepareDatas();
                    that.makeToast('success', 'Сообщение', 'Элемент сохранен');
                }
            }
        },

        saveItem() {
            const that = this;

            if ((that.selected.kat == null || that.selected.kat == 0) ||
                (that.selected.name_ru.length == 0)) {
                this.makeToast('danger', 'Предупреждение', 'Заполните обязательные для заполнения поля *!');
                return;
            }

            that.$set(that.selected, 'full_code', that.selected.kat + '' +
                ((that.selected.cls == null) ? 0 : parseInt(that.selected.cls)) +
                ((that.selected.pcl == null) ? 0 : parseInt(that.selected.pcl)) +
                ((that.selected.spf == null) ? 0: parseInt(that.selected.spf)));

            that.save();
        } // подготовка к сохранению элемент
    }
};
</script>
<style>
.is-hidden {
    display: none !important;
}
</style>