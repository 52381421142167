<template>
    <b-form>
        <div v-for="(item, ind) in root_func.child" :key="'abp-'+ind">
            <dict-item :item="item" code-field="abp" v-on:collapse-show="loadSubItemsPrg"
                       v-on:modified="modifiedItem" v-on:undo-modified="undoModifiedItem"
                       v-on:add-child="addChild" v-on:check-child="checkChild"
                       v-on:delete-item="deleteItem" v-on:save-item="saveItem"
            >
                <div v-for="(itpgr) in item.child" :key="'pgr_'+itpgr.id">
                    <dict-item :item="itpgr" code-field="prg" v-on:collapse-show="loadSubItemsPrg"
                               variant="warning" level=1 v-on:modified="modifiedItem"
                               v-on:undo-modified="undoModifiedItem" v-on:add-child="addChild"
                               v-on:check-child="checkChild" v-on:delete-item="deleteItem"
                               v-on:save-item="saveItem">
<!--                        <div v-for="item in root_func.child" :key="'abp_'+item.id">-->
<!--                            <dict-item :item="item" code-field="abp" v-on:collapse-show="loadSubItems"-->
<!--                                       variant="success" level=2 v-on:modified="modifiedItem"-->
<!--                                       v-on:undo-modified="undoModifiedItem" v-on:add-child="addChild"-->
<!--                                       v-on:check-child="checkChild" v-on:delete-item="deleteItem"-->
<!--                                       v-on:save-item="saveItem">-->
<!--                                <div v-for="itprg in item.child" :key="'prg_'+itprg.id">-->
<!--                                    <dict-item :item="itprg" code-field="prg"-->
<!--                                               v-on:collapse-show="loadSubItems" variant="danger" level=3-->
<!--                                               v-on:modified="modifiedItem"-->
<!--                                               v-on:undo-modified="undoModifiedItem"-->
<!--                                               v-on:add-child="addChild" v-on:check-child="checkChild"-->
<!--                                               v-on:delete-item="deleteItem" v-on:save-item="saveItem">-->
<!--                                        <div v-if="itprg.child">-->
<!--                                            <div v-for="itppr in itprg.child" :key="'ppr_'+itppr.id">-->
<!--                                                <dict-item :item="itppr" code-field="ppr" variant="info"-->
<!--                                                           :leaf=true level=4 v-on:modified="modifiedItem"-->
<!--                                                           v-on:undo-modified="undoModifiedItem"-->
<!--                                                           v-on:delete-item="deleteItem"-->
<!--                                                           v-on:save-item="saveItem"-->
<!--                                                           v-on:open-reestr="openReestr">-->
<!--                                                </dict-item>-->
<!--                                            </div>-->
<!--                                            <div v-if="itprg.child.length === 0">-->
<!--                                                Нет подпрограмм-->
<!--                                            </div>-->
<!--                                        </div>-->
<!--                                        <b-form-group class="text-right item-tool item-tool">-->
<!--                                            <b-button @click="addChild(itprg)">Новый элемент</b-button>-->
<!--                                        </b-form-group>-->
<!--                                    </dict-item>-->
<!--                                </div>-->
<!--                                <b-form-group class="text-right item-tool">-->
<!--                                    <b-button @click="addChild(itabp)">Новый элемент</b-button>-->
<!--                                </b-form-group>-->
<!--                            </dict-item>-->
<!--                        </div>-->
<!--                        <b-form-group class="text-right item-tool">-->
<!--                            <b-button @click="addChild(itpgr)">Новый элемент</b-button>-->
<!--                        </b-form-group>-->
                    </dict-item>
                </div>
<!--                <b-form-group class="text-right item-tool">-->
<!--                    <b-button @click="addChild(item)">Новый элемент</b-button>-->
<!--                </b-form-group>-->
            </dict-item>
        </div>
        <b-form-group class="text-right item-tool">
            <b-button @click="addChild(root_func)">Новый элемент</b-button>
        </b-form-group>
        <b-modal title="Реестр показателей" id="reestr" size="xl">
            <reestr :programme="reestrProgramme"></reestr>
        </b-modal>
    </b-form>
</template>

<script>
import DictItem from '@/modules/budget/components/Dict/DictItem';
import Reestr from '@/modules/budget/components/Dict/Reestr';

export default {
    name: 'EbkFunc',
    components: {DictItem, Reestr},
    data () {
        return {
            reestrProgramme: {},
            root_func: {
                id: 0
            },
            modifiedList: [],
            idMap: {},
            parentMap: {}
        }
    },
    methods: {
        saveItem (item, e) {
            this.saveToServer(item)
        },
        openReestr (item, e,) {
            console.log('open reestr', item)
            const prg = this.findParent(item)
            const abp = this.findParent(prg)
            this.reestrProgramme = {
                abp: item.abp,
                prg: item.prg,
                ppr: item.ppr,
                abp_name_ru: abp.name_ru,
                prg_name_ru: prg.name_ru,
                ppr_name_ru: item.name_ru
            }
            this.$bvModal.show('reestr')
        },
        checkChild (item, e, codeField) {
            let state = null;
            if (item[codeField] === undefined) {
                state = false
            } else {
                if (item[codeField].toString().trim() === '') {
                    state = false
                }
                const parentFind = this.findParent(item)
                let i = 0;
                while (i < parentFind.child.length) {
                    const ch = parentFind.child[i]
                    if (ch[codeField].toString() === item[codeField].toString()) {
                        if (ch != item) {
                            state = false;
                            i = parentFind.child.length;
                            break;
                        }
                    }
                    i++
                }
            }
            e.setCodeState(state)
        },
        async deleteItem (item, e) {
            if (item.id > 0) {
                await this.loadSubItems(item, e);
            }
            if ((item.child) && (item.child.length > 0)) {
                this.makeToast('danger', 'Элемент имеет дочерние элементы, невозможно удалить !', 'Предупреждение');
            } else {
                let delete_result = true
                if (item.id > 0) {
                    delete_result = await this.deleteItemServer(item)
                }
                if (delete_result) {
                    const findParent = this.findParent(item);
                    for (let i = 0; i < findParent.child.length; i++) {
                        if (findParent.child[i] === item) {
                            findParent.child.splice(i, 1);
                        }
                    }
                    this.makeToast('success', 'Элемент удален !', 'Сообщение');
                }
            }
        },
        addChild (item, e) {
            const row = this.createItem(item);
            this.$set(item, 'hasnew', true);
            item.child.push(row);
            this.addToModified(item);
            this.idMap[row.id] = row;
            this.parentMap[row.id] = item.id;
        },
        createItem (item) {
            const row = {
                id: -1,
                name_ru: 'Новый элемент',
                modified: true,
                isnew: true
            };
            if (item.type === 1) {
                row.gr = item.gr;
                row.type = 2;
            }
            if (item.type === 2) {
                row.type = 3;
                row.gr = item.gr;
                row.pgr = item.pgr;
            }
            if (item.type === 3) {
                row.type = 4;
                row.gr = item.gr;
                row.pgr = item.pgr;
                row.abp = item.abp;
            }
            if (item.type === 4) {
                row.type = 5;
                row.gr = item.gr;
                row.pgr = item.pgr;
                row.abp = item.abp;
                row.prg = item.prg;
            }
            return row;
        },
        modifiedItem (item, e) {
            this.$set(item, 'modified', true);
            this.addToModified(item);
        },
        undoModifiedItem (item, olditem) {
            this.$set(item, 'name_ru', olditem.name_ru);
            this.$set(item, 'name_kk', olditem.name_kk);
            this.$set(item, 'short_name_ru', olditem.short_name_ru);
            this.$set(item, 'short_name_kk', olditem.short_name_ru);
            this.$set(item, 'beg_date', olditem.beg_date);
            this.$set(item, 'end_date', olditem.end_date);
            this.$set(item, 'budget_level_id', olditem.budget_level_id);
            this.removeFromModified(item);
            if (item.isnew) {
                this.deleteItem(item)
            }
        },
        addToModified (item) {
            for (const it of this.modifiedList) {
                if (it === item) {
                    return
                }
            }
            this.modifiedList.push(item);
        },
        removeFromModified (item) {
            this.$set(item, 'modified', false);
            for (let i = 0; i < this.modifiedList.length; i++) {
                if (this.modifiedList[i] === item) {
                    if (!this.modifiedList[i].modified) {
                        this.modifiedList.splice(i, 1);
                    }
                }
            }
        },
        async loadSubItems (item, e) {
            if (item.child === undefined) {
                await this.getByParent(item);
            }
        },
        async loadSubItemsPrg (item, e) {
            console.log('loadSubItemsPrg', item.abp, e)
            if (item.child === undefined) {
               await this.getByAbp(item);
            }
        },
        findParent (item) {
            const id = item.id;
            const parId = this.parentMap[id];
            return this.idMap[parId];
        },
        async getByParent (item) {
            const subitems = await this.loadParentFetch(item);
            this.$set(item, 'child', subitems);
            for (const it of subitems) {
                this.idMap[it.id] = it;
                this.parentMap[it.id] = item.id;
            }
        },
        async getByAbp (item) {
            const subitems = await this.loadParentFetch(item);
            this.$set(item, 'child', subitems);
            for (const it of subitems) {
                this.idMap[it.id] = it;
                this.parentMap[it.id] = item.id;
            }
        },
        async loadParentFetch (item) {
            let subitems;
            console.log(item)
            try {
                if (item.id) {
                    const response = await fetch('/api-py/get-dict-ved-childs-by-parent/' + item.type + '/' + item.id);
                    subitems = await response.json();
                } else {
                    const response = await fetch('/api-py/get-dict-ved-abp/');
                    subitems = await response.json();
                }
            } catch (err) {
                this.makeToast('danger', err.toString(), 'Ошибка запроса');
            }
            return subitems;
        },
        async deleteItemServer (item) {
            let isdelete = false;
            try {
                const response = await fetch('/api-py/delete-func-dict-item/' + item.id);
                const result = await response.json()
                isdelete = result.result === 'success';
            } catch (err) {
                this.makeToast('danger', err.toString(), 'Ошибка запроса');
            }
            return isdelete;
        },
        async saveToServer (item) {
            const response = await fetch('/api-py/dict-func-item-save', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json;charset=utf-8'
                },
                body: JSON.stringify(item)
            });
            const result = await response.json();
            if (response.status === 200) {
                if (result.result === 'success') {
                    this.removeFromModified(item)
                    this.makeToast('success', 'Элемент сохранен', 'Сообщение')
                }
            }
        },
        makeToast (variant = null, tostbody, title) {
            this.$bvToast.toast(tostbody, {
                title: title,
                variant: variant,
                autoHideDelay: 4000,
                solid: true
            });
        }
    },
    mounted () {
        this.idMap[0] = this.root_func
        this.loadSubItems(this.root_func);
    }
}
</script>

<style scoped>

</style>