<template>
    <div class="inner-container">
        <div class="section-title budget"><i class="icon icon-calculator-coins"></i><span>Бюджетное планирование</span></div>
        <div class="section-subtitle"><i class="icon icon-grid"></i>Классификация расходов</div>
       <b-tabs>
           <b-tab title="Test" v-if="false">
               <b-table :items="items" :fields="fields" striped responsive="sm">
                   <template #cell(show_details)="row">
                       <b-button size="sm" @click="row.toggleDetails" class="mr-2">
                           {{ row.detailsShowing ? 'Hide' : 'Show'}} Details
                       </b-button>
                   </template>

                   <template #row-details="row">
                        adsfgchvnmbj,n
                   </template>
               </b-table>
           </b-tab>
           <b-tab title="Табличный">
               <div class="filter-container">
                   <div class="left-content"></div>
                   <div class="right-content">
                       <div class="filter-actions">
                           <b-button variant="default">Сбросить</b-button>
                           <b-button variant="default">Отменить</b-button>
                           <b-button variant="success">Сохранить изменения</b-button>
                       </div>
                   </div>
               </div>
               <div class="table-container">
                   <b-table
                       :fields="tableFields"
                       :items="root_func.child"
                       responsive="true"
                       bordered
                       head-variant="light"
                       sticky-header
                       no-border-collapse
                   >
                       <template #cell(action)="data">
                           <b-button
                                     @click="loadSubItems(data.item)">
                               <i class="icon icon-keyboard icon-rotate-270"></i>
                               <i class="icon icon-keyboard icon-rotate-90"></i>
                           </b-button>
                       </template>
                   </b-table>
               </div>

           </b-tab>
           <b-tab title="Функциональная/Ведомственная">
               <ebkFuncVedom></ebkFuncVedom>
           </b-tab>
           <!-- <b-tab title="Ведомственная">
               <ebk-vedomst></ebk-vedomst>
           </b-tab> -->
           <b-tab title="Поступления">
               <dict-doh></dict-doh>
           </b-tab>
           <b-tab title="Обоснования">
               <dict-jba></dict-jba>
           </b-tab>
           <!-- <b-tab title="Проекты">
               <dict-bp></dict-bp>
           </b-tab> -->
       </b-tabs>
    </div>
</template>

<script>
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import DictItem from '@/modules/budget/components/Dict/DictItem.vue';
import Reestr from '@/modules/budget/components/Dict/Reestr.vue';
import EbkFunc from '@/modules/budget/components/EbkFunc';
import EbkFuncVedom from '@/modules/budget/components/EbkFuncVedom';
import EbkVedomst from '@/modules/budget/components/EbkVedomst';
import DictJba from '@/modules/budget/components/Dict/DictJba';
import DictBp from '@/modules/budget/components/Dict/DictBp';
import DictDoh from '@/modules/budget/components/Dict/DictDoh';

export default {
    name: 'EbkDicts',
    components: { DictJba, DictDoh, EbkFuncVedom },
    data() {
        return {
            reestrProgramme: {},
            fields: ['first_name', 'last_name', 'show_details'],
            items: [
                { isActive: true, age: 40, first_name: 'Dickerson', last_name: 'Macdonald' },
                { isActive: false, age: 21, first_name: 'Larsen', last_name: 'Shaw' },
                {
                    isActive: false,
                    age: 89,
                    first_name: 'Geneva',
                    last_name: 'Wilson',
                    _showDetails: true
                },
                { isActive: true, age: 38, first_name: 'Jami', last_name: 'Carney' }
            ],
            root_func: {
                id: 0
            },
            tableFields: [
                {
                    key: 'action',
                    label: ''
                },
                {
                    key: 'gr',
                    label: 'Функциональная группа',
                    sortable: true
                },
                {
                    key: 'pgr',
                    label: 'Функциональная подгруппа',
                    sortable: true
                },
                {
                    key: 'abp',
                    label: 'Администатор бюджетных программ'
                },
                {
                    key: 'prg',
                    label: 'Программа'
                },
                {
                    key: 'ppr',
                    label: 'Подпрогрмма'
                },
                {
                    key: 'name_ru',
                    label: 'Наименования'
                },
                {
                    key: 'type',
                    label: 'Тип'
                }
            ]
        };
    },
    computed: {},
    methods: {
        openReestr(item, e) {
            console.log('open reestr', item);
            const prg = this.findParent(item);
            const abp = this.findParent(prg);
            this.reestrProgramme = {
                abp: item.abp,
                prg: item.prg,
                ppr: item.ppr,
                abp_name_ru: abp.name_ru,
                prg_name_ru: prg.name_ru,
                ppr_name_ru: item.name_ru
            };
            this.$bvModal.show('reestr');
        },
        makeToast(variant = null, tostbody, title) {
            this.$bvToast.toast(tostbody, {
                title: title,
                variant: variant,
                autoHideDelay: 4000,
                solid: true
            });
        }
    },
    mounted() {

    }
};
</script>
<style>
.is-hidden {
    display: none !important;
}
</style>
<style scoped>
.item-tool {
    margin-top: 5px;
}
</style>