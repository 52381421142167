<template>
    <b-container>
        <b-form-group
            id="fieldset-horizontal_1"
            label-cols-sm="2"
            label-cols-lg="2"
            content-cols-sm
            content-cols-lg="10"
            :label="programme.abp.toString()"
            label-for="input-horizontal_1"
        >
            <b-form-input id="input-horizontal_1" :value="programme.abp_name_ru" readonly></b-form-input>
        </b-form-group>
        <b-form-group
            id="fieldset-horizontal_2"
            label-cols-sm="2"
            label-cols-lg="2"
            content-cols-sm
            content-cols-lg="10"
            :label="programme.prg.toString()"
            label-for="input-horizontal_2"
        >
            <b-form-input id="input-horizontal_2" :value="programme.prg_name_ru" readonly></b-form-input>
        </b-form-group>
        <b-form-group
            id="fieldset-horizontal_3"
            label-cols-sm="2"
            label-cols-lg="2"
            content-cols-sm
            content-cols-lg="10"
            :label="programme.ppr.toString()"
            label-for="input-horizontal_3"
        >
            <b-form-input id="input-horizontal_3" :value="programme.ppr_name_ru" readonly></b-form-input>
        </b-form-group>
        <b-form-group label="Регион">
            <b-form-select :options="listReg" v-model="selectedRegion" value-field="code"></b-form-select>
        </b-form-group>
        <b-form-group label="Год">
            <b-form-select :options="years" v-model="year" value-field="value"></b-form-select>
        </b-form-group>
        <b-table :fields="tableFields" :items="reestrData">
            <template #head(action)="data">
                <b-button @click="addNewItem">+</b-button>
            </template>
            <template #cell(status)="data">
                <b-form-input class="text-left"
                              v-model="data.item.status" readonly>
                </b-form-input>
            </template>
            <template #cell(name_ru)="data">
                <b-form-input class="text-left"
                              v-model="data.item.name_ru" @change="onChange(data)">
                </b-form-input>
            </template>
            <template #cell(name_kk)="data">
                <b-form-input class="text-left"
                              v-model="data.item.name_kk" @change="onChange(data)" >
                </b-form-input>
            </template>
            <template #cell(unit_code)="data">
                <b-form-select :options="units"
                               value-field="code"
                               text-field="name_ru"
                               v-model="data.item.unit_code" @change="onChange(data)">
                </b-form-select>
            </template>
            <template #cell(beg_date)="data">
                <b-form-datepicker class="text-left"
                                   v-model="data.item.beg_date"
                                   :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                                   :style="{ width: '180px'}"
                                   @input="onChange(data)"
                >
                </b-form-datepicker>
            </template>
            <template #cell(end_date)="data">
                <b-form-datepicker class="text-left"
                                   v-model="data.item.end_date"
                                   :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                                   :style="{ width: '180px'}"
                                   @input="onChange(data)"
                >
                </b-form-datepicker>
            </template>
            <template #cell(action)="data">
                <b-button @click="saveItem(data)">Сохранить</b-button>
                <b-button @click="deleteItem(data)">Удалить</b-button>
                {{data.item.id}}
            </template>
        </b-table>
<!--        <pre>{{ selectedRegion }}</pre>-->
    </b-container>
</template>

<script>
import {Ax} from '@/utils';

export default {
    name: 'Reestr',
    props: {
        programme: {}
    },
    data () {
        return {
            listReg: [],
            units: [],
            selectedRegion: '450101',
            year: 2021,
            years: [
                {
                    value: 2021,
                    text: 2021
                },
                {
                    value: 2022,
                    text: 2022
                },
                {
                    value: 2023,
                    text: 2023
                },
                {
                    value: 2024,
                    text: 2024
                }
            ],
            tableFields: [
                {
                    key: 'status',
                    label: 'Статус'
                },
                {
                    key: 'name_ru',
                    label: 'Наименование рус.'
                },
                {
                    key: 'name_kk',
                    label: 'Наименование каз'
                },
                {
                    key: 'unit_code',
                    label: 'Единица измерения'
                },
                {
                    key: 'beg_date',
                    label: 'Дата начала'
                },
                {
                    key: 'end_date',
                    label: 'Дата окончания'
                },
                {
                    key: 'action',
                    label: 'Действия'
                }
            ],
            reestrData: [

            ]
        }
    },
    methods: {
        addNewItem() {
            const i = {
                status: 0,
                name_ru: 'Название',
                name_kk: 'Название',
                unit_code: 'Название',
                beg_date: null,
                end_date: null,
            }
           this.reestrData.push(i)
        },
        onChange(data) {
            data.item.status = 1
        },
        deleteItem(data) {
            if (data.item.id) {
                const saved_data = {
                    meta: this.programme,
                    region: this.selectedRegion,
                    item: data.item
                }
                Ax(
                    {
                        url: '/api-py/save-reestr-item/', //  Тело файла
                        method: 'DELETE',
                        data: saved_data
                    },
                    (server_data) => {
                        console.log('Delete data', data.index)
                        this.reestrData.splice(data.index, 1)
                    },
                    (error) => {
                        this.error = error;
                    }
                );
            } else {
                this.reestrData.splice(data.index, 1)
            }
        },
        saveItem(data) {
            const saved_data = {
                meta: this.programme,
                region: this.selectedRegion,
                item: data.item
            }
            Ax(
                {
                    url: '/api-py/save-reestr-item/', //  Тело файла
                    method: 'POST',
                    data: saved_data
                },
                (server_data) => {
                    console.log('Saved data')
                    data.item.id = server_data.id
                    data.item.status = 2
                },
                (error) => {
                    this.error = error;
                }
            );
        },
        loadItems() {
            const saved_data = {
                meta: this.programme,
                region: this.selectedRegion,
            }
            Ax(
                {
                    url: '/api-py/get-reestr-items/', //  Тело файла
                    method: 'POST',
                    data: saved_data
                },
                (server_data) => {
                    console.log(server_data, typeof (server_data[0].beg_date))
                    this.reestrData = server_data
                    for (const itm of this.reestrData) {
                        if (itm.beg_date) {
                            itm.beg_date = new Date(itm.beg_date)
                        }
                        if (itm.end_date) {
                            itm.end_date = new Date(itm.end_date)
                        }
                    }
                },
                (error) => {
                    this.error = error;
                }
            );
        },
        loadRegions () {
            Ax(
                {
                    url: '/api-py/get-regions-by-obl/' + '45', //  Тело файла
                    method: 'GET'
                },
                (data) => {
                    this.listReg = JSON.parse(JSON.stringify(data));
                },
                (error) => {
                    this.error = error;
                }
            );
        },
        loadUnits () {
            Ax(
                {
                    url: '/api-py/get-dict-unit-all/', //  Тело файла
                    method: 'GET'
                },
                (data) => {
                    this.units = JSON.parse(JSON.stringify(data));
                },
                (error) => {
                    this.error = error;
                }
            );
        }
    },
    mounted () {
        this.loadRegions()
        this.loadUnits()
        this.loadItems()
    }
}
</script>

<style scoped>

</style>